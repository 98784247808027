// NOTE: DO NOT USE CONST FOR ENUMS
// Refer: https://stackoverflow.com/questions/40227401/const-enum-in-typescript

export enum INVENTORY_ITEM_TYPE {
  REEL = 'REEL',
}

export enum TOAST_KIND {
  SUCCESS = 'success',
  WARNING = 'warning',
}

export enum INVENTORY_ACTION_TYPE {
  DISPATCH = 'dispatch',
  REFILL = 'refill',
  CONSUME = 'consume',
  PRODUCE = 'produce',
}

export enum INVENTORY_STATUS {
  LOW = 'LOW',
}

export enum UNIT_TYPE {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

export enum INV_ITEMS_LISTING_TYPE {
  AVAILABLE_INVENTORY_ITEMS = 'AVAILABLE_INVENTORY_ITEMS',
  INVENTORY_ITEM_LOGS = 'INVENTORY_ITEM_LOGS',
}

export enum TRANSACTION_TYPE {
  SALES = 'SALES',
  PURCHASE = 'PURCHASE',
}

export enum TRANSACTION_TYPE_REPORT {
  SALES = 'SALES_REPORT',
  PURCHASE = 'PURCHASE_REPORT',
}

export enum PARTY_TYPE {
  CUSTOMER = 'CUSTOMER',
  VENDOR = 'VENDOR',
  VENDOR_CUSTOMER = 'VENDOR_CUSTOMER',
}

export enum CURRENCY_CODE {
  INR = 'INR',
}

export enum LOCALE {
  EN_IN = 'en-IN',
}

export enum TZ {
  UTC = 'UTC',
  ASIA_KOLKATA = 'Asia/Kolkata',
}

export enum LANGUAGE_CODE {
  EN = 'en',
  HI = 'hi',
  HI_EN = 'hi-en',
}

export enum INVENTORY_TYPE {
  RM = 'RM',
  FG = 'FG',
}

export enum INDUSTRY_TYPE {
  PAPER = 'PAPER',
  CORRUGATION = 'CORRUGATION',
  PLASTIC = 'PLASTIC',
  METAL = 'METAL',
  ELECTRICAL = 'ELECTRICAL',
  OTHERS = 'OTHERS',
}
export enum RM_TYPE {
  PAPER = 'PAPER',
  PLASTIC = 'PLASTIC',
  METALS = 'METALS',
  TEXTILE = 'TEXTILE',
  ELECTRICALS = 'ELECTRICALS',
  CHEMICALS = 'CHEMICALS',
  ELECTRONICS = 'ELECTRONICS',
  OTHERS = 'OTHERS',
}

export enum INVENTORY_UNIT {
  KGS = 'kgs',
  MTR = 'mtr',
  LTR = 'ltr',
  PCS = 'pcs',
  TON = 'ton',
  FTS = 'fts',
  SQF = 'sqf',
  SQM = 'sqm',
  ROL = 'rol',
  BAG = 'bag',
  BOX = 'box',
  GMS = 'gms',
  YDS = 'yds',
  INS = 'ins',
  LBS = 'lbs',
  MMS = 'mms',
  CMS = 'cms',
  REM = 'rem',
  SHT = 'sht',
  DRM = 'drm',
  CFT = 'cft',
  GRS = 'grs',
  BTL = 'btl',
  CAN = 'can',
  DZN = 'dzn',
  NOS = 'nos',
  QTL = 'qtl',
  REL = 'rel',
  PRS = 'prs',
  SET = 'set',
  KTA = 'kta',
  OTH = 'oth',
  CTN = 'ctn',
}

export enum USER_STATUS {
  NEW_USER = 'NEW_USER',
  ACTIVE = 'ACTIVE',
}

export enum DATE_AND_TIME {
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  LAST_30_DAYS = 'LAST_30_DAYS',
  THIS_MONTH = 'THIS_MONTH',
  LAST_MONTH = 'LAST_MONTH',
  CUSTOM_RANGE = 'CUSTOM_RANGE',
}

export enum PLATFORM {
  WEB = 'web',
}

export enum COUNTRY_CODE {
  IN = '91',
}
